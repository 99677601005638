































































































export default {
  props: {
    exam: { required: true },
    modalId: { required: true },
  },

  data() {
    return {
      url: window.location.origin,
      studentAppUrl: process.env.VUE_APP_STUDENT_APP_URL,
    }
  },

  computed: {
    testStatus: function() {
      return this.exam.is_auth_required ? 'Privátny' : 'Verejný'
    },
    testStatusClass: function() {
      return this.exam.is_auth_required ? 'w-color-red' : 'w-color-green'
    }
  },

  methods: {
    close() {
      this.$bvModal.hide(this.modalId)
    },

    copyText(inputId) {
      const dashboardUrlToCopy = document.querySelector(inputId)
      dashboardUrlToCopy.setAttribute('type', 'text')
      dashboardUrlToCopy.select()
      document.execCommand('copy')
      try {
        document.execCommand('copy')
        this.$bvToast.toast(dashboardUrlToCopy.value, {
          title: 'Skopírované',
          autoHideDelay: 5000,
          variant: 'a-success',
          toaster: 'b-toaster-top-center',
          solid: true
        })
      } catch (err) {
        alert('Ups, kopírovanie sa nepodarilo.')
      }
      dashboardUrlToCopy.setAttribute('type', 'hidden')
      window.getSelection()?.removeAllRanges()
    }
  }
}
